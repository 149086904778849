<template>
  <div>
    <template v-for="(item, index) in  faqObj.faqs_header || [] " >
      <v-row class="gradient-faq"  :class="$vuetify.breakpoint.xsOnly ? 'apply-faq-height': ''" :key="index">
        <v-container :class="$vuetify.breakpoint.lgAndUp ? 'common-width': ''">
            <v-row class="ma-0" :class="$vuetify.breakpoint.mdAndDown ? 'apply-column' : ''">
              <v-col class=" d-flex align-center" xl="6" lg="6" xs="12" sm="12" md="12">
                <v-container>
                  <!-- <h1 class="font-weight-bold mb-4" style="color:#ffffff !important; line-height: 60px;"
                    :class="$vuetify.breakpoint.smAndUp ? 'text-h3': 'text-h6'">{{ faqObj.faqs_header[0].title }}
                  </h1> -->
                  <!-- <h1 class="font-weight-bold mb-4" style="color:#ffffff !important; line-height: 60px;"
                    :class="$vuetify.breakpoint.smAndUp ? 'text-h3': 'text-h6'">{{ item.title }}
                  </h1> -->
                  <h1 class="font-weight-bold mb-4" style="color:#ffffff !important;"
                    :class="$vuetify.breakpoint.smAndUp ? 'text-h2': 'text-h6'">{{ item.title }}
                  </h1>
                </v-container>
              </v-col>
              <v-col xl="6" lg="6" xs="12" sm="12" md="12">
                <!-- <v-img contain  :aspect-ratio=" $vuetify.breakpoint.xsOnly ? 2 : ''" :height=" $vuetify.breakpoint.xsOnly ? '' : 600" src="../../assets/images/faq.png"></v-img> -->
                <v-img contain  :aspect-ratio=" $vuetify.breakpoint.xsOnly ? 2 : ''" :height=" $vuetify.breakpoint.xsOnly ? '' : 600"  :src="item.image"></v-img>
            </v-col>
          </v-row>
        </v-container>
        <square-animation></square-animation>
      </v-row>
    </template>
    <v-row  justify="center">
      <v-card flat width="100%" class="d-flex align-center pa-12">
        <v-container fill-height class="d-flex align-center">
          <v-row justify="center">
            <v-col  xs="12" lg="7" xl="7" md="12" sm="8" v-for="(item, index) in  faqObj.question_and_answer" :key="index"
              class="d-flex justify-center scaling-element ">
              <v-container>
                <v-row justify="center" @click="showDescription(index, item.show)">
                  <v-card class="pa-3 apply-background-faq pointer" tile flat  width="1000">
                    <div style="display:flex;flex-direction:row">
                      <div class="font-weight-bold pa-3" style="word-wrap:break-word !important;color:#20195F !important;">
                        {{ item.title }}
                      </div>
                      <v-spacer></v-spacer>
                      <v-expand-transition>
                        <v-icon color="#000000" :size="$vuetify.breakpoint.xs ? '15' : ''" class="remove-scaling"> {{ item.show ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }} </v-icon>
                      </v-expand-transition>
                    </div>
                    <!-- <v-card-title  style="color:#20195F !important;">
                      <div style="width:calc(100% - 10%);display:flex;flex-wrap:no-wrap !important">
                        <span style="  word-wrap: break-word; " :class="$vuetify.breakpoint.mdAndDown ? 'text-subtitle-1 font-weight-bold' : ''">{{ item.title }} </span>
                      </div>
                    <v-spacer></v-spacer>
                     <v-expand-transition>
                      <v-icon color="#000000" :size="$vuetify.breakpoint.xs ? '15' : ''" class="remove-scaling"> {{ item.show ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }} </v-icon>
                     </v-expand-transition>
                    </v-card-title> -->
                  </v-card>
                </v-row>
                <v-row justify="center">
                  <v-expand-transition>
                    <v-card tile flat  v-if="item.show" width="1000" class="apply-background-faq">
                      <v-card-subtitle>{{item.faq_description}}</v-card-subtitle>
                    </v-card>
                  </v-expand-transition>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-row>
  </div>
</template>
<script>
import { api } from '../../plugins/axios'
export default {
  data () {
    return {
      faqObj: {}
    }
  },
  mounted () {
    this.getFAQ()
    this.scrollToTop()
  },
  components: {
    'square-animation': () => import('../SquareAnimation/index.vue')
  },
  methods: {
    showDescription (index, show) {
      this.closeOtherFAQs(index)
      this.faqObj.question_and_answer[index].show = !show
    },
    getFAQ () {
      api.get('/faqs')
        .then((response) => {
          this.faqObj = response.data
          this.faqObj.question_and_answer = this.faqObj.question_and_answer.map(faq => ({ ...faq, show: false }))
        })
        .catch((error) => {
          console.log(error.message)
          if (error.response && error.response.status === 500) {
            this.$router.push('/technical-difficulties')
          }
        })
    },
    closeOtherFAQs (index) {
      this.faqObj.question_and_answer.forEach((faq, i) => {
        if (i !== index) {
          faq.show = false
        }
      })
    },
    scrollToTop () {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Adds smooth scrolling behavior
      })
    }
  }
}
</script>

<style lang="scss">
.gradient-faq {
//  background: linear-gradient(to right, #4d84e4, #c0c9db);
 background: linear-gradient(to right, #1E88E5, #c0c9db);
}
.apply-background-faq {
       background-color:#FAFAFA !important
}
.apply-faq-height {
  height:  50vh !important;
}
</style>
